import axios, { AxiosError } from 'axios';
import { NamingConvention, SupportType } from './getModels';
import { environment } from '../utils/environment';
import { isMultidoor } from './utils';
import { StepperContextData } from '../contexts';

export interface Candidate {
  model_name: string;
  support: SupportType;
  ring_to_open?: boolean;
  has_video?: boolean;
  terminal_labels: string[];
}

export interface ModelsListResponse {
  candidates: Candidate[];
  estimated_support_status: SupportType;
}

const listModels = async (
  brandName: string,
  terminals: NamingConvention[],
  multiDoor: boolean,
  isParallel: boolean
) => {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const multidoor = isMultidoor({ isParallel, multiDoor } as StepperContextData);
    const response = await axios.post(
      `${environment.basePath}/brands/${brandName}/identify_model`,
      JSON.stringify({
        terminal_labels: terminals,
        multidoor,
      }),
      requestOptions
    );

    return response.data as ModelsListResponse;
  } catch (error) {
    const message = (error as AxiosError)?.message || undefined;
    const code = (error as AxiosError)?.code || undefined;
    throw new AxiosError(message, code);
  }
};
export default listModels;
