import axios, { AxiosError } from 'axios';
import { environment } from '../utils/environment';
import { isMultidoor } from './utils';
import { StepperContextData } from '../contexts';

export enum SchemePreference {
  ModelName = 'model_name',
  TerminalLabels = 'terminal_labels',
}

export interface Brand {
  brand_name: string;
  terminal_labels?: NamingConvention[];
  popular_models?: NamingConvention[];
  identification_scheme_preference?: SchemePreference;
}

export type NamingConvention = string;

export type SupportType = 'full_support' | 'no_support' | 'possible_support';

export interface ModelVariant {
  backplate_number: string;
  support: SupportType;
  ring_to_open: boolean;
  has_video: boolean;
}

export interface Model {
  model_name: string;
  support?: SupportType;
  variants: ModelVariant[];
}

const getModels = async (
  brand: string,
  multiDoor: boolean,
  isParallel: boolean
) => {
  try {
    const multidoor = isMultidoor({ isParallel, multiDoor } as StepperContextData);
    const res = await axios.get(
      `${environment.basePath}/brands/${encodeURIComponent(
        brand
      )}/models?group_into_families=true&multidoor=${multidoor}`
    );

    return res.data as Model[];
  } catch (error) {
    const message = (error as AxiosError)?.message || undefined;
    const code = (error as AxiosError)?.code || undefined;
    throw new AxiosError(message, code);
  }
};
export default getModels;
